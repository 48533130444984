@font-face {
  font-family: "Assistant";
  src: url("fonts/Assistant-VariableFont_wght.ttf") format("truetype");
}
@font-face {
  font-family: "webfont0";
  src: url("fonts/BundesSerifWeb-Regular.eot?#iefix") format("embedded-opentype"), url("fonts/BundesSerifWeb-Regular.woff") format("woff"),
    url("fonts/BundesSerifWeb-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "webfont1";
  src: url("fonts/BundesSerifWeb-Italic.eot?#iefix") format("embedded-opentype"), url("fonts/BundesSerifWeb-Italic.woff") format("woff"),
    url("fonts/BundesSerifWeb-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "webfont2";
  src: url("fonts/BundesSerifWeb-Bold.eot?#iefix") format("embedded-opentype"), url("fonts/BundesSerifWeb-Bold.woff") format("woff"),
    url("fonts/BundesSerifWeb-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "webfont3";
  src: url("fonts/BundesSerifWeb-BoldItalic.eot?#iefix") format("embedded-opentype"), url("fonts/BundesSerifWeb-BoldItalic.woff") format("woff"),
    url("fonts/BundesSerifWeb-BoldItalic.ttf") format("truetype");
}

@keyframes actung {
  0% {
    border-color: #eff7f2;
  }
  20% {
    border-color: #c87c2a;
  }
  100% {
    border-color: #eff7f2;
  }
}
@keyframes emptyanim {
}
.equal {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}

@font-face {
  font-family: "swebfont0";
  src: url("fonts/BundesSansWeb-Regular.eot?#iefix") format("embedded-opentype"), url("fonts/BundesSansWeb-Regular.woff") format("woff"),
    url("fonts/BundesSansWeb-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "swebfont1";
  src: url("fonts/BundesSansWeb-Italic.eot?#iefix") format("embedded-opentype"), url("fonts/BundesSansWeb-Italic.woff") format("woff"),
    url("fonts/BundesSansWeb-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "swebfont2";
  src: url("fonts/BundesSansWeb-Bold.eot?#iefix") format("embedded-opentype"), url("fonts/BundesSansWeb-Bold.woff") format("woff"),
    url("fonts/BundesSansWeb-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "swebfont3";
  src: url("fonts/BundesSansWeb-BoldItalic.eot?#iefix") format("embedded-opentype"), url("fonts/BundesSansWeb-BoldItalic.woff") format("woff"),
    url("fonts/BundesSansWeb-BoldItalic.ttf") format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
